<template>
  <div class="is-menu">
    <div class="columns is-menu-options">
      <div :class="'column '" tabindex="1">
        <router-link :to="'/'">Início</router-link>
      </div>
      <div :class="'column '" tabindex="2">
        <router-link :to="'/objetivos'">Objetivos</router-link>
      </div>
      <div :class="'column '" tabindex="3">
        <router-link :to="'/equipa'">Equipa</router-link>
      </div>
      <div :class="'column '" tabindex="4">
        <router-link :to="'/arquivo'">Arquivo Digital</router-link>
      </div>
      <div :class="'column '" tabindex="5">
        <router-link :to="'/noticias'">Notícias</router-link>
      </div>
      <div :class="'column '" tabindex="6">
        <router-link :to="'/bibliografia'">Bibliografia</router-link>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "links-menu",
  data:()=>({
    focused: ['', '', '', '', '', '']
  }),
  methods:{
    changeSelected(){
      if(this.$route.path === '/'){
        this.focused = ['focused', '', '', '', '', '']
      }
      if(this.$route.path === '/objetivos'){
        this.focused = ['','focused', '', '', '', '']
      }
      if(this.$route.path === '/equipa'){
        this.focused = ['', '', 'focused', '', '', '']
      }
      if(this.$route.path === '/noticias'){
        this.focused = ['', '', '', 'focused', '', '']
      }
      if(this.$route.path === '/bibliografia'){
        this.focused = ['', '', '', '', 'focused', '']
      }
    }
  },
  created() {
    //this.changeSelected();
  }
}
</script>

<style scoped>
.is-menu{
  background-color: #f5993d;
}

.is-menu-options{
  width: 70%;
  margin: 0 auto 0 auto;
}

.is-menu-options .column{
  padding: 0;
}

.is-menu-options .column a{
  color: #dcdcdc !important;
  font-weight: bold;
  height: 50px;
  line-height:50px;
  display: block;
}

.is-menu-options .column:hover{
  background-color: #ff8000;
}

.is-menu-options .column:focus-within{
  background-color: #ff8000;
}

.router-link-exact-active {
  background-color: #ff8000;
  display: block;
  cursor: default;
}

.focused{
  background-color: #ff8000;
}

.vertical-align{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
</style>