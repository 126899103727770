<template>
  <footer class="footer">
    <div class="">
      <div class="columns is-vcentered content">
        <div class="column is-6">
        </div>
        <div class="column is-2">
          <a href="http://portal.cehr.ft.lisboa.ucp.pt" target="_blank">
            <img src="../../assets/CEDIS.png" alt="Centro de Estudos de História Religiosa">
          </a>
        </div>
        <div class="column is-2">
          <a href="http://www.cehr.ft.lisboa.ucp.pt" target="_blank">
            <img src="../../assets/FDUNL.png" alt="Centro de Estudos de História Religiosa" class="align-right">
          </a>
        </div>
        <div class="column is-2">
          <a href="http://www.fct.pt" target="_blank">
            <img src="../../assets/FCT.png" alt="Fundação para a Ciência e Tecnologia">
          </a>
        </div>
      </div>
      <div id = "copyright"><h6 class="title is-6">&copy; {{ new Date().getFullYear() }} CEDIS</h6></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter"
}
</script>

<style scoped>
.footer{
  background: #fff2e6;
  text-decoration: none;
  width: 100%;
  margin: 12px auto 0 auto;
  padding: 0;
  border-top: 1px #666666 dotted;
}

.content{
  margin-top: 16px;
  margin-left: 70px;
  margin-right: 16px;
  margin-bottom: 16px !important;
}

.align-right {
  float: right;
}

.footer h6 {
  text-align: center;
  padding-top:10px;
}

.footer .sponsors {
  padding-left: 40px;
  padding-right: 40px;
}

.footer .sponsors img {
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

#copyright {
  border-top: 1px #666666 dotted;
  padding: 0 0 16px 0;
  width: 100%;
}

.footer .sponsors img{
  margin-left: 0;
}
</style>