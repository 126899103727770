<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column" v-if="false">
        <div class="columns">
          <div class="column">
            <p class="block">O pluralismo jurídico é um tema central da historiografia sobre os impérios, mas os trabalhos a ele
              dedicado na historiografia portuguesa têm privilegiado as reflexões doutrinais das elites coloniais, e não a ação
              dos agentes locais da justiça colonial e das populações locais envolvidas. Sabe-se, contudo, que o funcionamento do
              pluralismo jurídico emergiu das práticas sociais dessas pessoas, os administradores e os “súbditos” da justiça
              colonial. Ambos o usaram estrategicamente, recorrendo seletivamente a normas e fórmulas europeias e locais e criando,
              nessas interações, novas versões, coloniais, dos direitos tradicionais africanos e do direito português. </p>

            <p class="block is-final-part">O projeto ‘Pluralismo Jurídico no Império Português (séculos XVIII-XX) (LEGALPL)’ propõe uma análise mais dinâmica
              e completa da interação entre as várias esferas normativas presentes no império português, atenta a estas duas
              dimensões, a central e a local, em simultâneo.</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="block" style="padding-top: 30px;">Legal pluralism was a main issue in the doctrinal texts of the colonial elites, as Portuguese
              historiography has already shown. Nevertheless, there is evidence that legal pluralism in colonial situations and
              the solutions it engendered emerged from the social practices of those (administrators and “subjects”) involved.
              Therefore, we need to look to both of these instances, the legal reasoning by colonial agents at central and local
              levels and the agency of “native subjects”,  the way they appropriated Portuguese law, their versions of customary law.
            </p>

            <p class="block">Not considering those impacted by the law, as Portuguese historiography has done until now, is missing elements
              that are critical to the understanding of legal pluralism in the Portuguese Empire, and the contexts in which it has
              evolved.
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <p class="block">
          O site Pluralismo Jurídico no Império Português é um dos principais outputs do projecto Pluralismo Jurídico no Império Português, 
          séculos XVIII-XX (LEGALP), financiado pela Fundação para a Ciência e Tecnologia (PTDC/DIR-OUT/30873/2017) e desenvolvido no 
          CEDIS-Centro de Investigação & Desenvolvimento sobre Direito e Sociedade da Faculdade de Direito da Universidade Nova de 
          Lisboa/Nova School of Law e no ICS-Instituto de Ciências Sociais da Universidade de Lisboa
        </p> 
        <p class="block">
          Este site tem como principal objectivo disponibilizar informação sobre pluralismo jurídico no Império português. O utilizador 
          encontrará nele informação sobre o Projecto (ver Objetivos), sobre a sua Equipa de Investigação (ver Equipa de Investigação),  
          sobre bibliografia relevante para o tema (ver Bibliografia),  e, sobretudo, um Arquivo Digital integrando uma base de dados 
          de Legislação Colonial impressa (1910-1970)  e um conjunto de Índices, ainda em construção, com informação relevante para o tema do projeto. 
        </p>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="is-panel">
              <splide :options="options">
                <splide-slide>
                  <img src="@/assets/Painel 1.jpg">
                </splide-slide>
                <splide-slide>
                  <img src="@/assets/Painel 2.jpg">
                </splide-slide>
                <splide-slide>
                  <img src="@/assets/Painel 3.jpg">
                </splide-slide>
                <splide-slide>
                  <img src="@/assets/Painel 4.jpg">
                </splide-slide>
                <splide-slide>
                  <img src="@/assets/Painel 5.jpg">
                </splide-slide>
              </splide>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  data:()=>({
    options: {
      rewind : true,
      width  : 400,
      gap    : '1rem',
      autoplay    : true,
    },
  }),
  created() {
  }
}
</script>

<style>
.is-final-part{
  border-bottom: 1px dotted #636363;
  padding-bottom: 40px;
}
.is-panel {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}
</style>
