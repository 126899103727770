import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/objetivos',
    name: 'Objectives',
    component: () => import(/* webpackChunkName: "about" */ '../views/Objectives.vue')
  },
  {
    path: '/equipa',
    name: 'Team',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team.vue')
  },
  {
    path: '/noticias',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/bibliografia',
    name: 'Bibliography',
    component: () => import(/* webpackChunkName: "about" */ '../views/Bibliography.vue')
  },
  {
    path: '/arquivo',
    name: 'Archive',
    component: () => import(/* webpackChunkName: "about" */ '../views/Archive.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  {
    path: '/pesquisa',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  {
    path: '/indices',
    name: 'Indexes',
    component: () => import(/* webpackChunkName: "about" */ '../views/ColonialIndexes.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  {
    path: '/documento/:id',
    name: 'Document',
    component: () => import(/* webpackChunkName: "about" */ '../views/Document.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
