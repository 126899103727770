import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSplide from '@splidejs/vue-splide';

Vue.use( VueSplide );

import './../node_modules/bulma/css/bulma.css'
import './../node_modules/bulma-carousel/dist/css/bulma-carousel.min.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
