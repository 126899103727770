<template>
<div>
  <div class="banner-container">
    <router-link :to="'/'"><img class="banner" src="@/assets/banner.png"></router-link>
  </div>
</div>
</template>

<script>
export default {
name: "Banner"
}
</script>

<style scoped>
.banner-container{
  width: 100%;
  z-index: 1000;
  margin: 0 auto 0 auto;
  height: 15%;
  line-height: 0;
}
.banner{
  width: 100%;
}
</style>