<template>
  <div id="app">
    <banner />
    <links-menu/>
    <router-view class="is-main-page"/>
    <site-footer />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  margin: 0;
  background-color: #fff2e6;
  font-family: Calibri Serif;
  color: #330000;
}

p{
  color: #330000;
  text-align: justify;
  font-size: 14px;
}

a{
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  color: #330000 !important;
  font-weight: bold;
  text-decoration: none;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.is-main-page{
  width: 1200px;
  margin: 120px auto 100px auto;
}
</style>
<script>
import Banner from "./components/fixed/Banner";
import LinksMenu from "./components/fixed/LinksMenu";
import SiteFooter from "./components/fixed/SiteFooter";
export default {
  components: {SiteFooter, LinksMenu, Banner}
}
</script>